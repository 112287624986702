import React, { useCallback, useContext, useState, useEffect } from 'react';
import { CancelToken } from 'axios';
import {
    AgrmType,
    checkValidCloudOrEnvironment,
    getAgrmData,
    getAgrmOrchestrations,
    getAgrmStates,
    restoreBuildToShare
} from '../../api/AGRMApi';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../Common/api/ABHub/ABHubGlobalConfig';
import {
    CompoundButton,
    DirectionalHint,
    MessageBar,
    MessageBarType,
    Icon,
    IContextualMenuItem,
    IContextualMenuProps,
    Stack,
} from '@fluentui/react';
import { JsonModal } from '../../../HubLayout/components/JsonModal';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { usePromise } from '../../../Common/hooks/usePromise';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';

interface IAgrmContextButtonProps {
    uniqueIdentifier?: string;
    type: AgrmType;
}

interface IProps {
    resetChoice?: () => void;
}

export const AgrmContextButton: React.FC<IAgrmContextButtonProps> = (
    props: IAgrmContextButtonProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [openModal, setOpenModal] = useState(false);
    const [jsonModalData, setJsonModalData] = useState<string>('');
    const [jsonModalHeader, setJsonModalHeader] = useState<string>('');
    const [items, setItems] = useState<IContextualMenuItem[]>([]);
    const [error, setError] = useState<string>('');

    const contextButtonStyles = {
        menuIcon: {
            display: 'none',
        },
        root: {
            paddingTop: '0px',
            paddingRight: '5px',
            paddingBottom: '0px',
            paddingLeft: '5px',
            maxHeight: '40px',
            minHeight: '40px',
            border: 'none',
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    const hideModal = () => {
        setOpenModal(false);
        setJsonModalData('');
        setError('');
    };

    const environment = getConfig(GlobalConfigProperties.Environment);
    const cloud = getConfig(GlobalConfigProperties.Cloud);

    const isValidEnv = (cld: string, env: string) => {
        try {
            checkValidCloudOrEnvironment(cld, env);
            return true;
        } catch {
            return false;
        }
    };

    const openModalBehavior = (header: string, stateFunction: Function) => {
        setOpenModal(true);
        setJsonModalHeader(header);
        stateFunction();
    };

    const getAgrmApiCall = async (
        apiCall: Function,
        cancelToken?: CancelToken
    ) => {
        if (props.uniqueIdentifier) {
            const result = await apiCall(
                props.uniqueIdentifier,
                props.type,
                cancelToken
            );
            const stringified = JSON.stringify(result, null, 2);
            setJsonModalData(stringified);
        } else {
            throw new Error(
                'Failed to get Agrm Information. Invalid Unique Identifier.'
            );
        }
    };

    const setGeneralData = useCallback(
        () => getAgrmApiCall(getAgrmData),
        [props.uniqueIdentifier]
    );
    const setAgrmOrchestrations = useCallback(
        () => getAgrmApiCall(getAgrmOrchestrations),
        [props.uniqueIdentifier]
    );
    const setAgrmStates = useCallback(
        () => getAgrmApiCall(getAgrmStates),
        [props.uniqueIdentifier]
    );

    const [, generalDataError, generalDataIsLoaded, generalDataStart] =
        usePromise(setGeneralData);

    const [, orchestrationDataError, orchestrationDataIsLoaded, orchestrationDataStart,] =
        usePromise(setAgrmOrchestrations);

    const [, stateDataError, stateDataIsLoaded, stateDataStart] =
        usePromise(setAgrmStates);

    useEffect(() => {
        if (generalDataError) {
            setError(generalDataError.message);
        } else if (orchestrationDataError) {
            setError(orchestrationDataError.message);
        } else if (stateDataError) {
            setError(stateDataError.message);
        }
    }, [generalDataError, orchestrationDataError, stateDataError]);

    const [messageBarChoice, setMessageBarChoice] = React.useState<
        string | undefined
    >(undefined);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
        undefined
    );
    const messageBarStylesSuccess = {
        root: {
             color: theme.semanticColors.bodyText,
        }
    };
    const resetChoice = useCallback(() => setMessageBarChoice(undefined), []);


    const ErrorExample = (p: IProps) => (
        <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={p.resetChoice}
            dismissButtonAriaLabel="Close"
        >
            {errorMessage}
        </MessageBar>
    );
    const SuccessExample = (p: IProps) => (
        <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
            onDismiss={p.resetChoice}
            dismissButtonAriaLabel="Close"
            styles={messageBarStylesSuccess}
        >
            Successfully started the build restore.
        </MessageBar>
    );

    // Use capabilities context
    const capabilities = useContext(CapabilityContext);
    // Only show Admin if user has Bridge.Admin role
    const showAdmin = capabilities.check(Capability.bridgeAdminRole);

    useEffect(() => {
        if (isValidEnv(cloud, environment)) {
            var temp = [];
            temp.push({
                key: 'general',
                text: 'General ' + props.type + ' Data',
                iconProps: { iconName: 'KnowledgeArticle' },
                onClick: () => {
                    openModalBehavior(
                        props.type + ' Information',
                        generalDataStart
                    );
                },
            });

            temp.push({
                key: 'orchestrations',
                text: props.type + ' Orchestrations',
                iconProps: { iconName: 'ServerProcesses' },
                onClick: () => {
                    openModalBehavior(
                        props.type + 'Orchestrations',
                        orchestrationDataStart
                    );
                },
            });

            temp.push({
                key: 'states',
                text: props.type + ' States',
                iconProps: { iconName: 'Org' },
                onClick: () => {
                    openModalBehavior(
                        props.type + ' States',
                        stateDataStart);
                },
            });

            //Todo: Change from only allow SRM Admin to allow for proper Service Tree team
            if (showAdmin && props.type === AgrmType.Build) {
                temp.push({
                    key: 'restore',
                    text: 'Restore Build',
                    iconProps: { iconName: 'Download' },
                    onClick: async () => {
                        var data = await restoreBuildToShare(props.uniqueIdentifier!);
                        if (data.succeeded && data.message) {
                            // show success
                            setErrorMessage('');
                            setMessageBarChoice('success');

                        } else if (!data.succeeded) {
                            // show error
                            setErrorMessage(
                                data.message ||
                                'There was an error sending the restore build command.'
                            );
                            setMessageBarChoice('error');
                        }
                    },
                });
            }

            setItems(temp);
        }

    }, [props.uniqueIdentifier]);

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        directionalHintFixed: true,
        items: items,
    };

    return isValidEnv(cloud, environment) ? (
        <Stack horizontal>
            <div className="AgrmContextButton-root">
                <CompoundButton menuProps={menuProps} styles={contextButtonStyles}>
                    <Icon iconName="MoreVertical" />
                </CompoundButton>
                <JsonModal
                    jsonData={error !== '' ? error : jsonModalData ?? ''}
                    header={jsonModalHeader ?? ''}
                    isOpen={openModal}
                    closeModal={hideModal}
                    dataLoaded={
                        generalDataIsLoaded ||
                        orchestrationDataIsLoaded ||
                        stateDataIsLoaded ||
                        error !== ''
                    }
                />
            
                
            </div>
            
            {
                messageBarChoice === 'error' && (
                    <ErrorExample resetChoice={resetChoice} />
                )
            }
            {
                messageBarChoice === 'success' && (
                    <SuccessExample resetChoice={resetChoice} />
                )
            }
        </Stack>
        
    ) : null;
};
